@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@700&display=swap');

body {
    color: var(--semi-color-text-0);
    background-color: var( --semi-color-bg-0);
    margin: 0
}

html, body, #app, #root, #work_area, #app_loading {
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.App{
    height: 100vh;
}

.verticalMenu .semi-navigation-header-list-outer{
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
}

.verticalMenu{
    min-width: 240px;
}

.verticalMenu .semi-navigation-header-text{
    font-family: 'Exo 2', monospace;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

#work_area {
    display: flex;
    flex-grow: 1;
}

#app_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292d33;
    color: #fff;
    font-size: 24px;
}

.textBlock{
    display: block;
    line-height: 24px;
}

.cardBody .semi-card-body{
    height: 270px;
}

.cardBodyHuge .semi-card-body{
    height: 400px;
}

.sizedIcon{
    font-size: 18px;
    line-height: 24px;
    vertical-align: middle;
}

.verticalMenu .semi-navigation-list-wrapper{
    height: auto !important;
}

.verticalMenu .semi-navigation-sub-open,
.verticalMenu .semi-navigation-sub{
    padding-left: 15px;
}

.verticalMenu .semi-navigation-header {
    flex-direction: column;
    align-items: center;
}

.grid-container{
    padding: 30px;
    overflow-y: auto;
    scrollbar-width: thin;
    background: linear-gradient(180deg, #121616 0%, #1a2020 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.page-header {
    text-align: center;
}

.page-title {
    color: #d0dede;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: block;
    margin-bottom: 15px; 
    line-height: 64px;
}

.card-row {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

.contact-card-row {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    position: relative;
}

.card-col {
    display: flex;
    justify-content: center;
}

.card-content-fixed,
.card-content {
    width: 100%;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

.contact-card-content:hover,
.card-content:hover {
    transform: translateY(-8px);
}

.info-card {
    max-width: 650px;
    margin: 20px;
    background: linear-gradient(145deg, #1f2525 0%, #2a3232 100%);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-title-l {
    color: #d0dede;
    font-size: 18px; 
    display: flex;
    align-items: center;
    gap: 10px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    word-break: break-word;
    font-weight: bold;
}

.card-title-xl {
    color: #d0dede;
    font-size: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    word-break: break-word;
    font-weight: bold;
}

.card-body {
    padding: 25px;
    color: #b0c0c0;
    background: rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.card-content-inner-services {
    flex-grow: 1;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    min-height: 320px;
    max-height: 320px;
}

.card-content-inner-projects {
    flex-grow: 1;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    min-height: 470px;
    max-height: 470px;
}

.card-paragraph {
    margin: 5px 0;
    color: #b0c0c0;
    gap: 10px;
    display: flex;
}

.card-paragraph strong {
    color: #d0dede;
}

.tag-custom {
    font-weight: bold;
    line-height: 24px;
    vertical-align: baseline;
}

.tag-custom-no-border {
    border: none;
}

.tag-green {
    background: #2d5a5a;
    border: none;
}

.tag-red {
    background: #5a2d2d;
    border: none;
}

.tag-white {
    border: none;
}

.tag-teal {
    background: #2d5a5a;
}

.tag-cyan {
    background: #2d5a6a;
}

.tag-amber {
    background: #5a4a2d;
}

.tag-blue {
    background: #2d4a6a;
}

.button-group-custom {
    margin-left: 10px;
}

.button-custom {
    color: #a0b0b0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    margin: 0 6px;
    padding: 6px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.button-custom:hover {
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
}

.popover-custom {
    background: #2a3232;
    border: none;
}

.popover-content {
    color: #fff;
}

.no-data {
    color: #a0b0b0;
    text-align: center;
    padding: 60px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    word-break: break-word;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
    .page-title {
        font-size: 28px;
    }
    .about-content {
        font-size: 16px;
        padding: 0 10px;
    }
    .contact-card-col:last-child::before {
        display: none;
    }
    .contact-text-section {
        max-width: 100%;
    }
    .contact-card-content {
        max-width: 100%;
    }
}

.about-content {
    max-width: 900px;
    color: #b0c0c0;
}

.about-content p {
    margin: 20px 0;
    font-size: 18px;
    line-height: 1.8;
}

.card-resources {
    margin: 15px 0;
    margin-top: auto; 
}

.semi-card-header-wrapper-title {
    height: 32px;
    display: inherit;
}

.semi-typography .semi-icon {
    font-size: 18px;
    line-height: 32px;
    display: inherit;
}

.card-description {
    margin: 15px 0;
    line-height: 1.8;
    color: #b0c0c0;
    word-break: break-word;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-input,
.contact-textarea,
.contact-select {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px;
    color: #d0dede;
    font-size: 16px;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    word-break: break-word;
    display: flex;
    align-items: center;
}

.contact-input:focus,
.contact-textarea:focus,
.contact-select:focus {
    border-color: rgba(255, 255, 255, 0.3);
    outline: none;
}

.contact-input {
    min-height: 40px;
}

.contact-textarea {
    min-height: 120px;
    resize: vertical;
    align-items: flex-start;
}

.contact-select {
    width: 100%;
    min-height: 40px;
    cursor: pointer;
    overflow: hidden;
}

.contact-button {
    color: #a0b0b0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 10px 20px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.contact-button:hover {
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
}

.contact-card-paragraph {
    margin: 15px 0;
    color: #b0c0c0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact-card-paragraph strong {
    color: #d0dede;
}

.contact-card-paragraph a {
    color: #b0c0c0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-card-paragraph a:hover {
    color: #d0dede;
}

.contact-card-paragraph svg {
    width: 24px;
    height: 24px;
}

.contact-text-section {
    max-width: 450px;
    margin: 20px;
    color: #b0c0c0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contact-card-content {
    width: 100%;
    max-width: 700px;
    transition: transform 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.contact-text-title {
    color: #d0dede;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.contact-card {
    background: linear-gradient(145deg, #252a2a 0%, #323a3a 100%);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contact-card-col:last-child::before {
    content: '';
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    width: 2px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    transform: translateX(-50%);
}

.contact-page-text {
    color: #b0c0c0;
    font-size: 18px;
    max-width: 600px;
    display: block; 
}

.contact-card-col {
    display: flex;
    justify-content: center;
    height: 100%;
}

.ui-checkbox-group .semi-checkbox-inner-display {
    background: transparent !important;
    box-shadow: none !important;
    cursor: default !important;
}

.ui-checkbox-group .semi-checkbox-input,
.ui-checkbox-group .semi-checkbox-disabled{
    cursor: default !important;
}

.ui-checkbox-group .semi-checkbox-inner-display svg {
    color: #1f9b3a;
}

.section-title{
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    color: rgb(84, 169, 255);
}

.semi-chat-content::-webkit-scrollbar {
    width: 8px;
  }
  
.semi-chat-content::-webkit-scrollbar-thumb {
    background: #4CAF50;
    border-radius: 4px;
}
  
.semi-chat-content::-webkit-scrollbar-track {
    background: #2f2f2f;
}

.info-card-chat {
    margin: 20px;
    background: linear-gradient(145deg, #1f2525 0%, #2a3232 100%);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.semi-chat-container {
    scrollbar-width: thin !important;
}

.card-row.full {
    width: 100%;
}

.card-row.full .semi-chat{
    max-width: 100% !important;
    min-height: 600px;
    flex: 1;
}

.card-row.full .semi-chat .semi-chat-inner{
    height: 600px;
}

.card-row.full .semi-chat .semi-chat-inner .semi-chat-container{
    overflow-y: auto !important;
    overflow-x: hidden !important;
    scrollbar-width: thin !important;
}

.card-row.full .semi-chat .semi-chat-inner .semi-chat-content{
    background: #0000001a;
}

.card-row.full .semi-chat .semi-chat-inner .semi-chat-inputBox-container{
    padding: 4px !important;
}

.card-row.full .semi-chat .semi-chat-inner button.semi-chat-inputBox-sendButton.semi-button{
    height: 30px !important;
    width: 30px !important;
    border-radius: 25px !important;
    background-color: #54a9ff;
    color: #fff;
}